import * as THREE from 'three';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { PostEffectPass } from './PostEffectPass';
import isMobile from 'ismobilejs';

const FPS = 24;
const FPS_INTERVAL = 1000 / FPS;

const CLEAR_COLOR = 0x1C1917;
const LIGHT_COLOR = 0x4C4947;

const CUBE_GRID_SIZE = 8;
const CUBE_SIZE = 1;
const CUBE_GRID_POS = CUBE_SIZE + .025;

export default class CubeEffectApp {

    private _cam: THREE.PerspectiveCamera;
    private _light: THREE.SpotLight;
    private _scene: THREE.Scene;
    private _renderer: THREE.WebGLRenderer;
    private _composer: EffectComposer;
    private _domElementParent: HTMLElement;

    private _isMobile = isMobile(window.navigator);
    private _isRenderedForMobile: boolean;

    private _tick = 0;
    private _delta = 0;

    private _cubes: THREE.Object3D[] = [];
    private _instancedMesh: THREE.InstancedMesh;

    constructor(canvasId) {

        const domCanvas = document.getElementById('canvas-bg') as HTMLCanvasElement;
        if (!domCanvas) {
            console.error('no canvas!');
            return;
        }

        this._renderer = new THREE.WebGLRenderer({
            canvas: domCanvas,
            antialias: true
        });
        this._domElementParent = this._renderer.domElement.parentElement as HTMLElement;

        this._renderer.shadowMap.enabled = true;
        this._renderer.shadowMap.type = THREE.PCFShadowMap;
        this._renderer.autoClearColor = true;
        this._renderer.setClearColor(CLEAR_COLOR);
        this._renderer.setPixelRatio(window.devicePixelRatio);
        this._renderer.setSize(window.innerWidth, window.innerHeight);

        this._cam = new THREE.PerspectiveCamera(35,
            this._domElementParent.offsetWidth / this._domElementParent.offsetHeight,
            0.1, 25);
        this._cam.rotation.set(THREE.MathUtils.degToRad(-61.63), THREE.MathUtils.degToRad(-12.45), THREE.MathUtils.degToRad(-21.76))
        this._cam.position.set(-4.854, 9.915, 7.071);

        this._scene = new THREE.Scene();

        this._scene.add(new THREE.AmbientLight(CLEAR_COLOR, 1));

        this._light = new THREE.SpotLight(LIGHT_COLOR, .4);
        this._light.position.set(5.983, 5.666, 4.735);
        this._light.distance = 26;
        this._light.angle = 1;
        this._light.decay = 2;
        this._light.penumbra = 1;
        this._light.castShadow = true;
        this._light.shadow.mapSize.set(2048, 2048);
        this._light.shadow.camera.near = 0.1;
        this._light.shadow.camera.far = this._cam.far;
        this._light.shadow.radius = 2;
        this._light.shadow.bias = 0;
        this._scene.add(this._light);

        this._prepareMeshes();

        //

        this._composer = new EffectComposer(this._renderer);
        this._composer.addPass(new RenderPass(this._scene, this._cam));

        this._composer.addPass(new PostEffectPass(0.25, 0.5, 2048));

        //

        this._updateForResize();
        this._animate(0);
        window.addEventListener('resize', this._onWindowResize.bind(this));

    }

    private _prepareMeshes() {
        const cubeGeom = new RoundedBoxGeometry(CUBE_SIZE, CUBE_SIZE, CUBE_SIZE, 6, .04);
        const cubeMat = new THREE.MeshPhongMaterial({});

        // It's strangely slower :/
        /* this._instancedMesh = new THREE.InstancedMesh(cubeGeom, cubeMat, Math.pow(CUBE_GRID_SIZE * 2, 2));
        this._instancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        this._instancedMesh.castShadow = true;
        this._instancedMesh.receiveShadow = true;
        this._scene.add(this._instancedMesh); */

        let cube;
        for (let x = -CUBE_GRID_SIZE; x < CUBE_GRID_SIZE; x++) {
            for (let y = -CUBE_GRID_SIZE; y < CUBE_GRID_SIZE; y++) {

                cube = this._instancedMesh ? new THREE.Object3D() : new THREE.Mesh(cubeGeom, cubeMat);
                cube.position.set(x * CUBE_GRID_POS, -.05 + Math.random() * -.2, y * CUBE_GRID_POS)
                cube.userData.defY = cube.position.y;
                cube.userData.ySpeed = .25 + Math.random() * 1;
                this._cubes.push(cube);

                if (!this._instancedMesh) {
                    cube.castShadow = true;
                    cube.receiveShadow = true;
                    this._scene.add(cube);
                }
            }
        }
    }

    private _updateMeshes() {
        this._cubes.forEach((cube, i) => {
            cube.position.y = cube.userData.defY * Math.sin(this._tick * cube.userData.ySpeed);

            if (this._instancedMesh) {
                cube.updateMatrix();
                this._instancedMesh.setMatrixAt(i, cube.matrix);
            }
        });

        if (this._instancedMesh) {
            this._instancedMesh.instanceMatrix.needsUpdate = true;
        }
    }

    private _markedForResize: boolean;
    private _onWindowResize() {
        this._markedForResize = true;
    }

    private _updateForResize() {
        this._markedForResize = false;

        this._cam.aspect = this._domElementParent.offsetWidth / this._domElementParent.offsetHeight;
        this._cam.updateProjectionMatrix();

        this._renderer.setSize(this._domElementParent.offsetWidth, this._domElementParent.offsetHeight);

        if (this._composer)
            this._composer.setSize(this._domElementParent.offsetWidth, this._domElementParent.offsetHeight);
    }

    private _render() {
        if (this._composer)
            this._composer.render();
        else
            this._renderer.render(this._scene, this._cam);
    }

    private _animate(currentDelta) {
        requestAnimationFrame(this._animate.bind(this));

        const deltaDiff = currentDelta - this._delta;

        if (deltaDiff < FPS_INTERVAL)
            return;

        if (!this._isMobile.any || (this._isMobile.any && !this._isRenderedForMobile) || this._markedForResize) {

            if (!this._isMobile.any)
                this._updateMeshes();

            this._render();
            this._isRenderedForMobile = true;

            this._tick += FPS_INTERVAL / 1000;
        }

        if (this._markedForResize)
            this._updateForResize();

        this._delta = currentDelta;
    }
}