import {
    ShaderMaterial,
    UniformsUtils
} from 'three';
import { Pass, FullScreenQuad } from 'three/examples/jsm/postprocessing/Pass';
import { PostEffectShader } from './PostEffectShader';

export class PostEffectPass extends Pass {

    uniforms: typeof PostEffectShader.uniforms;
    material: ShaderMaterial;
    fsQuad: FullScreenQuad;

    constructor(noiseIntensity, scanlinesIntensity, scanlinesCount) {

        super();

        const shader = PostEffectShader;

        this.uniforms = UniformsUtils.clone(shader.uniforms);

        this.material = new ShaderMaterial({

            uniforms: this.uniforms,
            vertexShader: shader.vertexShader,
            fragmentShader: shader.fragmentShader

        });

        if (noiseIntensity !== undefined) this.uniforms.nIntensity.value = noiseIntensity;
        if (scanlinesIntensity !== undefined) this.uniforms.sIntensity.value = scanlinesIntensity;
        if (scanlinesCount !== undefined) this.uniforms.sCount.value = scanlinesCount;

        this.fsQuad = new FullScreenQuad(this.material);

    }

    render(renderer, writeBuffer, readBuffer, deltaTime /*, maskActive */) {

        this.uniforms['tDiffuse'].value = readBuffer.texture;
        this.uniforms['time'].value += deltaTime;

        if (this.renderToScreen) {

            renderer.setRenderTarget(null);
            this.fsQuad.render(renderer);

        } else {

            renderer.setRenderTarget(writeBuffer);
            if (this.clear) renderer.clear();
            this.fsQuad.render(renderer);

        }

    }

    dispose() {

        this.material.dispose();

        this.fsQuad.dispose();

    }

}
